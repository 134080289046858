import * as Yup from 'yup';
// import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { LoadingButton, MobileDatePicker } from '@material-ui/lab';
import { useTheme } from '@material-ui/core/styles';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import {
  Box,
  Card,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
  Link,
  FormHelperText,
  FormControlLabel,
  Button,
  Container,
  Autocomplete,
  IconButton,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';

// utils
import { fData } from '../../../utils/formatNumber';
import fakeRequest from '../../../utils/fakeRequest';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs.js';
import Page from 'src/components/Page.js';
//
import Label from '../../Label';
import { UploadAvatar, UploadSingleFile } from '../../upload';
import { Link as RouterLink } from 'react-router-dom';
import countries from '../user/countries';
import moment from 'moment';
import axios from 'axios';
import {REST_API_END_POINT} from '../../../constants/Defaultvalues';
import { Delete, KeyboardBackspaceOutlined } from '@material-ui/icons';
import Scrollbar from 'src/components/Scrollbar';

const VALUE = [
  { id: 0, value: 'Material ID' },
  { id: 1, value: 'Materials' },
  { id: 2, value: 'Unit Cost' },
  { id: 3, value: 'Stock' },
  { id: 4, value: 'Min value' },
  { id: 5, value: 'Max Value' },
  { id: 6, value: 'Quantity' },
  { id: 7, value: 'Sub total' },
  { id: 8, value: '' }
]

export default function CreateReceiveOrders() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme()
  const [vendors, setVendors] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const currentDate = moment().format('MM-DD-YYYY');
  const [dataSetValue,setDataSetvalue] = useState({minVal:'',maxVal:''})
  const userData = JSON.parse(localStorage.getItem('user'))
  const userId = userData.user_id
  const userType = userData.user_type
  const [productRows, setProductRows] = useState([{ product: '', qty: '', unitCost: '', total: '' }]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [selectedTaxCategory,setSelectedTaxCategory]=  useState(null);
  const [taxCategories,setTaxCategories]=  useState([]);
 const [taxValue,setTaxValue] = useState(null)

  let totalAmount = 0;


  const fetchTaxCategory = async () => {
    try{
        const response = await axios.post(`${REST_API_END_POINT}product/get-tax-category`)
        if(response.data.status === 1){
            setTaxCategories(response.data.taxResult);
        }else{
          setTaxCategories([])
        }
    }catch(error){
        console.log("Error - ",error);
        setTaxCategories([])
    }
  }
  
  const handleback = () => {
    window.history.back()
  }
  const handleAddRow = () => {
    setProductRows([...productRows, { product: '', qty: '', unitCost: '', total: '' }]);
  };

  const handleDeleteRow = (id) => {
    setProductRows(productRows.filter((row,index) => index !== id));
  };

  const handleProduct = (value) => {
    const productsWithQuantity = value.map((product) => ({
      ...product,
      quantity: selectedProducts.filter((p) => p.id === product.id)[0]?.quantity || 0,
      unitCost: selectedProducts.filter((p) => p.id === product.id)[0]?.unitCost || 0,     
    }));
    // console.log('productsWithQuantity*****',productsWithQuantity);
    setSelectedProducts(productsWithQuantity);
  };


  const calculateTotal = (price, quantity) => {
    const parsedPrice = parseFloat(price) || 0;
    const parsedQuantity = parseFloat(quantity) || 0;
    const subtotal = parsedPrice * parsedQuantity;
    return parseFloat(subtotal.toFixed(2));;
  };
  const calculateGrandTotal = (subtotal) => {
  const includeTax = values.includeTax === 1;
  // let taxAmount = 0;
  const taxAmount = selectedTaxCategory ? (selectedTaxCategory.type === 1 ? (subtotal * (selectedTaxCategory.value || 0) / 100) : (selectedTaxCategory.value || 0) ) : 0;
  // if (includeTax) {
  //   taxAmount = subtotal * taxPercentage / 100;
  // }
  return subtotal + taxAmount;
};

  const handleDeleteProduct = (productId) => {
    const filteredProduct = selectedProducts.filter(data => data.id !== productId);
    setFieldValue('products', filteredProduct);
    setSelectedProducts(filteredProduct);

    let newTotalAmount = 0;
    filteredProduct.forEach(product => {
      const subtotal = product.unitCost * product.quantity;
      newTotalAmount += subtotal;
    });
let fixedAmount =parseFloat(newTotalAmount).toFixed(2)
    setFieldValue('sub_total', fixedAmount);
    setFieldValue('grand_total', calculateGrandTotal(fixedAmount));
  };

  const LabelStyleHead = styled(Typography)(({ theme }) => ({
    ...theme.typography.h5,
    color: theme.palette.text.primary,
  }))
  
  const fetchVendors = async () => {
    try{
      const response = await axios.post(`${REST_API_END_POINT}clients/getAllClients`,{userId,userType,
        isVendor:1
      })
      if(response.data.status === 1){
        const vendors = response?.data?.customer;
        setVendors(vendors);
      }
    }
    catch(error){
      console.log("Error - ",error);
    }
  }
  // const fetchTaxCategory = async () => {
  //   try{
  //     const response = await axios.post(`${REST_API_END_POINT}product/get-tax-category`)
  //     if(response.data.status === 1){
  //       const tax = response.data.taxResult?.find((option) => option.name = 'tax')
  //       if(tax){
  //         setTaxPercentage(tax.value);
  //       }
  //       else{
  //         setTaxPercentage(0);
  //       }
  //     }
  //   }
  //   catch(error){
  //     console.log("Error - ",error);
  //   }
  // }
  const fetchProducts = async () => {
    try{
      const response = await axios.post(`${REST_API_END_POINT}product/get-all-materials`)
      if(response.data.status === 1){
        setProductsData(response.data.results);
      }
    }
    catch(error){
      console.log("Error - ",error);
    }
  }

  useEffect(()=>{
    fetchVendors();
    fetchTaxCategory()
    fetchProducts()
  },[])

  const NewProductSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    vendor: Yup.string().required('Vendor name is required'),
    receivedDate: Yup.date(),
    expectedDate: Yup.date(),
    transactionId: Yup.string().required('Transaction ID is required'),
    products: Yup.array().min(1,'Please select atleast one product'),
  });
  
  const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1)
  }));
  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      vendor:'',
      transactionId:'',
      receivedDate:moment().format('YYYY-MM-DD'),
      expectedDate:moment().format('YYYY-MM-DD'),
      includeTax:0,
      products: [],
      tax: 0,
      sub_total:0,
      grand_total:0,
      taxCategory:null
    },
    validationSchema: NewProductSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors, setFieldError }) => {
      try {
        // console.log('selectedProducts---',selectedProducts);
        values.taxCategory= selectedTaxCategory?.id
        const hasErrors = selectedProducts.some(product => product.unitCost === 0 || product.quantity === 0 || !product.unitCost || !product.quantity);
        if(hasErrors){
          enqueueSnackbar('Please enter valid Unit Cost and Quantity for all selected products', { variant: 'error' });
        }else{
          values.products = selectedProducts
          const response = await axios.post(`${REST_API_END_POINT}product/create-receive-order`,{values,userId,})
          if (response.data.status === 1) {
            enqueueSnackbar(`Order ${values['name']} created successfully`, { variant: 'success' });
          }
          else {
            enqueueSnackbar('Failed to create order', { variant: 'error' });
          }
          resetForm();
          setSubmitting(false);
          navigate(PATH_DASHBOARD.receiveOrder.root);
        }
      } catch (error) {
        console.error("Error : ", error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

  const updateGrandTotal = () => {
    const subtotal = values.sub_total || 0;
    // const grandTotal = calculateGrandTotal(subtotal);
    // const grandTotal = parseFloat(calculateGrandTotal(subtotal).toFixed(2));
    const taxAmount = selectedTaxCategory 
    ? (selectedTaxCategory.type === 1 
        ? (values.sub_total * (selectedTaxCategory.value || 0) / 100) 
        : (selectedTaxCategory.value || 0)) 
    : 0;

const fixedTaxAmount = parseFloat(taxAmount).toFixed(2);

const subTotal = parseFloat(values.sub_total || 0).toFixed(2);
const grandTotal = Number(subTotal) + Number(fixedTaxAmount);

setFieldValue('tax', fixedTaxAmount);
    setFieldValue('grand_total', grandTotal);
  };
  
  // Update grand total whenever sub_total changes
  useEffect(() => {
    updateGrandTotal();
  }, [values.sub_total]);

  const handleSwitchChange = (event) => {
    const includeTax = event.target.checked;
    let taxAmount = 0;
    if (includeTax) {
      taxAmount = values.sub_total * taxPercentage / 100;
    }
    const grandTotal = values.sub_total + taxAmount;
    setFieldValue('includeTax', includeTax ? 1 : 0);
    setFieldValue('tax', includeTax ? taxPercentage : 0);
    setFieldValue('grand_total', grandTotal);
  };

  const handleTaxCategoryChange = (newValue)=>{
    const taxAmount = newValue ? (newValue.type === 1 ? (values.sub_total * (newValue.value || 0) / 100) : (newValue.value || 0) ) : 0;
    let fixedAmount =parseFloat(taxAmount).toFixed(2)

    setFieldValue('tax', fixedAmount);
    let subTotal =parseFloat(values.sub_total).toFixed(2)
      
    const grandTotal = Number(subTotal) + Number(fixedAmount)
    setFieldValue('grand_total', grandTotal);
    console.log('dataaaaaaaaaaaaaaaaaaaaaaaaa',subTotal,fixedAmount,grandTotal)
  }

  return (
    <Page title="Create Orders | Sign-Wise Solution">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <HeaderBreadcrumbs heading=" Create Orders" />  
          <Button
            variant="outlined"
            type="submit"
            onClick={() => handleback()}
            sx={{ alignItems: 'right' }}
            startIcon={
              <Stack>
                <KeyboardBackspaceOutlined />
              </Stack>
            }
          >
            Back
          </Button>
        </Stack>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3} justifyContent="center" alignItems="center">
              <Grid item xs={12} md={11}>
                <Card sx={{ p: 8, margin: 'auto',height: 'fit-content' }}>
                  <Stack spacing={3}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <TextField
                            fullWidth
                            label="Name"
                            type="text"
                            {...getFieldProps('name')}
                            error={Boolean(touched.name && errors.name)}
                            helperText={touched.name && errors.name}
                        />
                        <Autocomplete
                            select
                            fullWidth
                            id="vendor"
                            options={vendors}
                            getOptionLabel={(option) => option.name}
                            value = {vendors?.find((option) => option.id === values.vendor) || null}
                            onChange={(event, newValue) =>{
                            if(newValue){
                                const selectedOption = vendors?.find(option => option.id === newValue.id)
                                if(selectedOption){
                                setFieldValue('vendor', newValue?.id);
                                }else {
                                setFieldValue('vendor', '');
                                }
                            }
                            else {
                                setFieldValue('vendor', '')
                            }
                            }}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select vendor"
                                fullWidth
                                SelectProps={{ native: true }}
                                error={Boolean(touched.vendor && errors.vendor)}
                                helperText={touched.vendor && errors.vendor}
                            />
                            )}
                        />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                        fullWidth
                        label="Transaction ID"
                        {...getFieldProps('transactionId')}
                        error={Boolean(touched.transactionId && errors.transactionId)}
                        helperText={touched.transactionId && errors.transactionId}
                    />
                    </Stack>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                      <Box
                        component={MobileDatePicker}
                        label="Date Received"
                        maxDate={currentDate}
                        value={values.receivedDate}
                        onChange={(date) => setFieldValue('receivedDate', date)}
                        renderInput={(params) => (
                          <TextField size="medium" {...params} fullWidth />
                        )}
                      />
                      <Box
                        component={MobileDatePicker}
                        label="Date Expected"
                        minDate={currentDate}
                        value={values.expectedDate}
                        onChange={(date) => setFieldValue('expectedDate', date)}
                        renderInput={(params) => (
                          <TextField size="medium" {...params} fullWidth />
                        )}
                      />
                    </Stack>
                    <Divider />
                    <LabelStyleHead>Materials</LabelStyleHead>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <Autocomplete
                          fullWidth
                          multiple
                          id="combo-box-demo"
                          // options={productsData || []}
                          options={productsData.filter(product => !selectedProducts.some(selected => selected.id === product.id)) || []}
                          value={values.products}
                          onChange={(event, newValue) => {
                            if (!newValue || newValue.length === 0) {
                              setFieldValue('products', newValue);
                              setSelectedProducts([]);
                              setFieldValue('sub_total', 0);
                              setFieldValue('grand_total', 0);
                            } else {
                              setFieldValue('products', newValue);
                              handleProduct(newValue);
                            }
                          }}
                          getOptionLabel={(option) => option.name ? option.name : ''}
                          getOptionSelected={(option, value) => option.id === value.id}
                          renderInput={(params) => (
                            <TextField {...params} required fullWidth
                            error={Boolean(touched.products && errors.products)}
                            helperText={touched.products && errors.products} 
                            label="Select material" />
                          )}
                        />
                      </Stack>             
                      {(selectedProducts?.length > 0) &&
                      <>
                        <LabelStyle>Selected Materials</LabelStyle>
                        <Scrollbar>
                          <TableContainer sx={{ minWidth: 500 }}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  {VALUE?.map((val) => (
                                    <TableCell key={val.id}>{val.value}</TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {selectedProducts?.map((data, index) => {
                                  const quantityFieldName = `quantity_${index}`;
                                  const unitCostFieldName = `unitCost_${index}`;
                                  // const rowTotal = calculateTotal(values[unitCostFieldName], values[quantityFieldName]);
                                  const rowTotal = calculateTotal(data.unitCost, data.quantity);
                                  // Accumulate the totalAmount
                                  totalAmount += rowTotal;
                                  values['sub_total'] = totalAmount
                                  console.log(data,'-----------data')
                                  return (
                                    <>
                                    <TableRow key={data.id}>
                                      <TableCell>{data.id}</TableCell>
                                      <TableCell>{data.name}</TableCell>                                 
                                      <TableCell>
                                        <TextField required
                                          fullWidth
                                          type='number'
                                          label="Unit Cost"
                                          sx={{width:"100px"}}
                                          value={data.unitCost || ''}
                                          onKeyPress={(event) => {
                                            // console.log('errorFieldId------',errorFieldId)
                                            if (event.key === '0' && event.target.value === '0' ) {
                                              event.preventDefault();
                                            }
                                            if (event.key === '0' && event.target.value === '' ) {
                                              event.preventDefault();
                                            }
                                            if (event.key === '-' ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(event) => {
                                            let newValue = event.target.value;
                                            if (newValue !== "0" && newValue !== "") {

                                              if (newValue.includes('.')) {
                                                const parts = newValue.split('.');
                                                if (parts[1].length > 2) {
                                                    newValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
                                                }
                                            }

                                              data['unitCost'] = newValue || 0;
                                              setFieldValue(unitCostFieldName, newValue);
                                            }
                                          
                                          }}
                                          error={Boolean(touched[unitCostFieldName] && errors[unitCostFieldName])}
                                          helperText={touched[unitCostFieldName] && errors[unitCostFieldName]}
                                        />
                                      </TableCell>
                                        <TableCell align='center'>{data.total_qty}</TableCell> 
                                        <TableCell align='center'>{data.min_value}</TableCell> 
                                        <TableCell align='center'>{data.max_value}</TableCell> 
                                      <TableCell>
                                        <TextField required
                                          fullWidth
                                          type='number'
                                          label="Quantity"
                                          // value={Number(data.quantity)}
                                          value={data.quantity || ''}
                                          onKeyPress={(event) => {
                                            // console.log('errorFieldId------',errorFieldId)
                                            if (event.key === '0' && event.target.value === '0' ) {
                                              event.preventDefault();
                                            }
                                            if (event.key === '0' && event.target.value === '' ) {
                                              event.preventDefault();
                                            }
                                            if (event.key === '.' ) {
                                              event.preventDefault();
                                            }
                                            if (event.key === '-' ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(event) => {
                                            const newValue = event.target.value;
                                            if (newValue !== "0" && newValue !== "") {
                                              // if (newValue === '' || newValue <= 0) {
                                              //   event.preventDefault();
                                              //   return;
                                              // }
                                              if (newValue.startsWith('-')) {
                                                newValue = newValue.slice(1);
                                              }
                                              data['quantity'] = newValue || 0;
                                              setFieldValue(quantityFieldName, newValue);
                                            }
                                          }}
                                          error={Boolean(touched[quantityFieldName] && errors[quantityFieldName])}
                                          helperText={touched[quantityFieldName] && errors[quantityFieldName]}
                                        />
                                      </TableCell>
                                      <TableCell> 
                                        {rowTotal}
                                        {/* {calculateTotal(values[unitCostFieldName], values[quantityFieldName])} */}
                                      </TableCell>
                                      <TableCell >
                                        <IconButton onClick={() => handleDeleteProduct(data.id)}>
                                          <Delete />
                                        </IconButton>
                                      </TableCell>
                                    </TableRow>
                                    </>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Scrollbar>
                      </>}
                     
                      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                        {/* <FormControlLabel
                            control={
                                <Switch
                                    onChange={handleSwitchChange}
                                    checked={values.includeTax === 1}
                                    defaultChecked
                                />
                            }
                            label="Include Tax"
                        />                      */}

                          <Autocomplete
                              // sx={{ width: 400 }}
                              fullWidth
                              id="combo-box-demo"
                              options={taxCategories || []}
                              value={selectedTaxCategory || null}
                              onChange={(event, newValue) => {
                                handleTaxCategoryChange(newValue)
                                setSelectedTaxCategory(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Select tax category" />
                              )}
                              getOptionLabel={(option) => option.name ? option.name : ''}
                            />
                      </Box>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <TextField
                          fullWidth
                          label="Sub Total"
                          {...getFieldProps('sub_total')}
                          error={Boolean(touched.sub_total && errors.sub_total)}
                          helperText={touched.sub_total && errors.sub_total}
                          InputLabelProps={{ style: { color: theme.palette.text.primary } }}
                          InputProps={{ readOnly:true }}
                        />
                        <TextField
                          fullWidth
                          label="Tax"
                          {...getFieldProps('tax')}
                          error={Boolean(touched.tax && errors.tax)}
                          helperText={touched.tax && errors.tax}
                          InputLabelProps={{ style: { color: theme.palette.text.primary } }}
                          InputProps={{ readOnly:true }}
                        />
                        <TextField
                          fullWidth
                          label="Grand Total"
                          {...getFieldProps('grand_total')}
                          error={Boolean(touched.grand_total && errors.grand_total)}
                          helperText={touched.grand_total && errors.grand_total}
                          InputLabelProps={{ style: { color: theme.palette.text.primary } }}
                          InputProps={{ readOnly:true }}
                        />
                      </Stack>

                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                      <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                        Create Order
                      </LoadingButton>
                      <Button variant="outlined" onClick={()=>{handleback()}} sx={{ marginLeft: '10px' }}>
                        Cancel
                      </Button>
                    </Box>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}