import * as Yup from 'yup'
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Form, FormikProvider, useFormik } from 'formik'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { useTheme } from '@material-ui/core/styles';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tooltip,
  List,
  ListItem,
  Menu,
  Popover,
  Box,
  Grid,
  Paper,
  ListItemText,
  TextField
} from '@material-ui/core';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import { getUserList, deleteUser } from '../../redux/slices/user';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Page from '../../components/Page';
import LoadingScreen from 'src/components/LoadingScreen';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user/list';
import  ProductToolbar  from 'src/components/_dashboard/user/list/ProductToolbar.js';
import  ProductsSearch  from 'src/components/_dashboard/blog/ProductsSearch.js';
import  ProductsMenu  from 'src/components/_dashboard/user/list/ProductsMenu.js';
import axios from 'axios';
import {COMPANY_NAME, REST_API, REST_API_END_POINT, UNITS} from '../../constants/Defaultvalues';
import moment from 'moment';
import Modal from 'src/components/_dashboard/blog/ProjectModel';
import { LoadingButton } from '@material-ui/lab';
import ConfirmationPopup from 'src/components/_dashboard/official-request/ConfirmationPopup';
import { useSnackbar } from 'notistack';
import { KeyboardBackspaceOutlined } from '@material-ui/icons';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  // { id: '' },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'services', label: 'Services', alignRight: false },
//   { id: 'added_by', label: 'Added By', alignRight: false },
//   { id: 'timestamp', label: 'Added On', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'options', label: '', alignRight: false},      
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) =>{
      const nameMatch = _user.name && _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      const tagsMatch = _user.tags && _user.tags.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      return nameMatch || tagsMatch;
    });
  }
  return stabilizedThis.map((el) => el[0]);
}


export default function LabourCostServicesType(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const [orderBy, setOrderBy] = useState('id');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [ProductList, setProductList] = useState([]);
  const [add, setAdd] = useState(false);
  const [isDelete, setIsDelete] = useState(false)
  const [data, setSingleData] = useState(null);
  const [Id, setId] = useState(null)
  const [refreshData, setRefreshData] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorPosition, setAnchorPosition] = useState({ top: 0, left: 0 });
  const [currentServices, setCurrentServices] = useState(null);
  const navigate = useNavigate()
  const userData = JSON.parse(localStorage.getItem('user'))
  const explodedPermissions =userData?.exploded_permissions

  useEffect(() => {
    dispatch(getUserList());
  }, [dispatch]);

  useEffect( async () => {
    try {
      const response = await axios.post(`${REST_API_END_POINT}product/get-all-labour-cost-service-types`);
      const productListData = response.data.results;
      if(productListData){
        setProductList(productListData);
        // console.log("ProductList : ",productListData);
      }
      else{
        setProductList([]);
      }
    }
      catch (error) {
      console.error("Error fetching data:", error);
    }
    finally{
      setLoading(false);
    }
  }, [refreshData]);

  // ...
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = slicedData.map((n) => n.id);
      const newSelectedNames = slicedData.map((n) => n.name);
      setSelected(newSelecteds);
      setSelectedNames(newSelectedNames);
      return;
    }
    setSelected([]);
    setSelectedNames([]);
  };

  const handleClick = (event, id,name) => {
    const selectedIndex = selected.indexOf(id);
    const selectedNameIndex = selectedNames.indexOf(name);
    let newSelected = [];
    let newselectedNames = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
      newselectedNames = newselectedNames.concat(selectedNames, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newselectedNames = newselectedNames.concat(selectedNames.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newselectedNames = newselectedNames.concat(selectedNames.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      newselectedNames = newselectedNames.concat(selectedNames.slice(0, selectedNameIndex), selectedNames.slice(selectedNameIndex + 1));
    }
    setSelected(newSelected);
    setSelectedNames(newselectedNames);
  };

  const handleSetData = (name,id)=>{
    setSingleData({name,id})
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // setSelected([]);
    // setSelectedNames([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleDeleteUser = (id,name,status) => {
    try {
      const values = {}
      values.id = Number(id)
      values.name = name
      values.status = status
      values.userId=userData.user_id
      axios.post(`${REST_API_END_POINT}product/delete-labour-cost-service-type`,values,{
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        if(response.data.status === 1){
          enqueueSnackbar(response.data.message, { variant: 'success' });
        }else if(response.data.status === 0){
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
        setIsDelete(false)
        setRefreshData(!refreshData)
      }).catch((error) => {
        console.error(error);
      })
      }
      catch (error) {
        console.error("Error fetching data:", error);
      }
  };

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Please enter the name of service type'),
  })
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.name || '',
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        if(data){
          values.id=data.id
          values.userId=userData.user_id
          values.status=1
        }else{
          values.userId=userData.user_id
          values.status=1
        }
        axios.post(REST_API_END_POINT + (data ? 'product/update-labour-cost-service-type' : 'product/add-labour-cost-service-type'), values,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((res) => {
            let data = res.data;
            resetForm();
            setSubmitting(false);
            setRefreshData(!refreshData)
            enqueueSnackbar(`${data.message}`, { variant: data.status==1 ? 'success' : 'error' });
          }).catch((error) => {
            console.error(error);
          })
          setSingleData(null)
          setAdd(false)
                 
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    },
  })

  const handlePopoverOpen = (event,service) => {
    setAnchorEl(event.currentTarget);
    setCurrentServices(service);
    setAnchorPosition({ top: event.clientY, left: event.clientX });
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - ProductList.length) : 0;
  const filteredUsers = applySortFilter(ProductList, getComparator(order, orderBy), filterName);
  const slicedData = filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title={`Service Types | ${COMPANY_NAME}`}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Box sx={{ display: 'flex', gap: 2}}>
        <Button
            onClick={()=> navigate(PATH_DASHBOARD.products.labour)}
            variant="outlined"
            startIcon={<KeyboardBackspaceOutlined />}
            sx={{mb:5.5}}
          >
            Back
          </Button>
          <HeaderBreadcrumbs
          heading="Service Types"
        />
        </Box>

        <Box>
          <Button
            // disabled={explodedPermissions?.some(permission => ['Add services'].includes(permission)) ? false : true}
            onClick={()=>{ setAdd(true) }}
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
          >
            Add Service Type 
          </Button>
        </Box>
        </Stack>
        <Card>
          <ProductToolbar 
            numSelected={selected.length} 
            filterName={filterName} 
            onFilterName={handleFilterByName} 
            selectedName={selectedNames} 
            selected={selected} 
            onDelete={() => {setSelected([]);setPage(0);}}
            item="service types"
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={slicedData?.length}
                  numSelected={selected?.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  // showAllCheckBox
                />
                <TableBody>
                  {loading ? (
                    <LoadingScreen
                      sx={{ top: 0,
                            left: 0,
                            width: 1,
                            zIndex: 9999,
                            position: 'fixed',
                            }}
                    />
                  ) : (
                    <>
                    {slicedData?.map((row) => {                                
                      let { id,name,timestamp,status, authorized_by,services } = row;
                      let statusText;               
                      (status === 1) ? statusText = "Active" : statusText = "Inactive";
                      const isItemSelected = selected.indexOf(id) !== -1;
                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            {/* <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id,name)} /> */}
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="left" justifyContent="center" spacing={2}>
                              <Typography variant="subtitle2">
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                                variant="subtitle2"
                                align="center"
                                sx={{ cursor: services?.length > 0 ? 'pointer' : 'auto',textDecoration: services?.length > 0 ? 'underline' :  'none' }}
                                onClick={() => services?.length > 0 ?handlePopoverOpen(event,services) : null}
                                >
                                {`${services?.length || 0} services`}
                            </Typography>
                          </TableCell>
                          <Popover
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            anchorReference="anchorPosition"
                            anchorPosition={anchorPosition}
                            onClose={handlePopoverClose}
                            // anchorOrigin={{
                            //   vertical: 'bottom',
                            //   horizontal: 'left',
                            // }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                          >
                            {currentServices?.length > 0 && (
                              <List dense>
                                {currentServices?.map((service, index) => (
                                  <ListItem key={index}>
                                    <ListItemText primary={`• ${service.service_name}`} />
                                  </ListItem>
                                ))}
                              </List>
                            )}
                          </Popover>
                          {/* <TableCell align="center">{authorized_by}</TableCell>
                          <TableCell align="center">{timestamp}</TableCell> */}
                          <TableCell align="center">
                            <Label
                              variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                              color={(status === 1 && 'info') || 'error'}
                            >
                              {sentenceCase(statusText)}
                            </Label>
                          </TableCell>

                          <TableCell align="center">
                          <UserMoreMenu
                            onEdit={()=>{
                            //   if(explodedPermissions?.some(permission => ['Edit services'].includes(permission))){
                                handleSetData(name,id)
                            //   }else{
                            //     enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
                            //   }
                            }}
                            text={status === 0 ? 'Activate' : null}
                            onDeactivate={() =>{
                            //   if(explodedPermissions?.some(permission => ['Activate/Deactivate Services'].includes(permission))) {
                                handleDeleteUser(id,name,status === 1 ? 0 : 1) 
                            //   }else {
                            //     enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
                            //   }
                            }}
                            onDelete={() => {
                            // if(explodedPermissions?.some(permission => ['Delete Services'].includes(permission))) {
                                setIsDelete(true)
                                setId(id)
                            // } else {
                            //     enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
                            // }
                            }} 
                          />
                          <ConfirmationPopup open={isDelete} handleClick={()=>handleDeleteUser(Id,name,2)} message={'You want to Delete this service type!'} handleClose={()=>setIsDelete(false)}/>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                    </>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} msg="Service Types"/>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>         

          <TablePagination
            rowsPerPageOptions={[10, 25, 50,100]}
            component="div"
            count={ProductList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    <Modal
      modalTitle={data ? 'Edit Service Type' : 'Add Service Type'}
      open={add || data}
      handleClose={() => {
        setSingleData(null)
        setAdd(false)
      }}
    >
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid
            container
            sx={{ alignItems: 'center', justifyContent: 'center' }}
            spacing={3}
          >
            <Grid item xs={12} md={8}>
              <Stack spacing={3}>
                <Stack
                  direction={{ xs: 'column', sm: 'column' }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                  <TextField
                    fullWidth
                    label="Service Type"
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Stack>

                <Box
                  sx={{
                    mt: 3,
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 1,
                  }}
                >
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    {data ? 'Save Changes' : 'Add service Type' }
                  </LoadingButton>
                  <Button
                    variant="outlined"
                    // type="submit"
                    onClick={() => {
                      setSingleData(null)
                      setAdd(false)
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </Modal>
    </Page>
  );
}
