import { useMemo } from 'react';

function useCompanyProfile() {
    const userData = JSON.parse(localStorage.getItem('user'));
    const workSpaceTitle = useMemo(() => ({
        COMPANY_LOGO: userData?.company_logo,
        COMPANY_NAME: userData?.company_name||'Signwise Solutions Oklahoma'
    }), [userData]);

    return workSpaceTitle;
}

export default useCompanyProfile;
