import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { paramCase } from 'change-case';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { BlockOutlined, TaskAltOutlined,LibraryBooksOutlined } from '@material-ui/icons';
// material
import { useTheme } from '@material-ui/core/styles';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText,  } from '@material-ui/core';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useSnackbar } from 'notistack';
import ConfirmationPopup from '../official-request/ConfirmationPopup';
import axios from 'axios';
import { REST_API_END_POINT } from 'src/constants/Defaultvalues';
import leadstatus from 'src/pages/dashboard/LeadStatus';
// routes


// ----------------------------------------------------------------------

AddEdit.propTypes = {
  onDelete: PropTypes.func,
  name: PropTypes.string
};

export default function AddEdit({ onDelete,onEdit, name,id,status,email,phone,onAct,leadName,leadEmail,leadPhone,leadId,leadStatus }) {
  const ref = useRef(null);
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false);
  const [openDeletePop,setOpenDeletePop] =useState(false)
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const userSession = JSON.parse(localStorage.getItem('user')); //data from localstorage
  const userId = userSession.user_id;
  const explodedPermissions =userSession?.exploded_permissions

  const changeStatus = async (id, contactStatus) => {
    try {
      let contactId = id;
      let status = contactStatus;
      // console.log("id-----", id, " status-----------", status)
      let response = await axios.post(`${REST_API_END_POINT}contacts/delete-contact`,{contactId,status,userId});
      // console.log("response-----------------", response.data);
      if (response.data.status === 1) {
        enqueueSnackbar(response.data.message, { variant: 'success' });
        onAct(response.data.message)
        navigate(PATH_DASHBOARD.crm.contact, { state: { data: response.data.message } });
      }
      else if (response.data.status === 0) {
        enqueueSnackbar(response.data.message, { variant: 'error' });
        navigate(PATH_DASHBOARD.crm.contact, { state: { data: response.data.message } });
      }
    }
    catch (error) {
      console.log("Delete error : ", error);
      enqueueSnackbar("Server Error...!", { variant: 'error' });
    }
  }

  const handleMenuItemClick = async (id, newStatus) => {
    setIsOpen(false)
    setOpenDeletePop(false);
    await changeStatus(id, newStatus);
  }


  const changeLeadStatus = async (updateLeadId, updateLeadStatus) => {
    try {
      let updateId = updateLeadId;
      let status = updateLeadStatus;
      // console.log("id-----", id, " status-----------", status)
      let response = await axios.post(`${REST_API_END_POINT}leads/delete-lead`,{updateId,status,userId});
      // console.log("response-----------------", response.data);
      if (response.data.status === 1) {
        enqueueSnackbar(response.data.message, { variant: 'success' });
        onAct(response.data.message)
        navigate(PATH_DASHBOARD.crm.lead, { state: { data: response.data.message } });
      }
      else if (response.data.status === 0) {
        enqueueSnackbar(response.data.message, { variant: 'error' });
        navigate(PATH_DASHBOARD.crm.lead, { state: { data: response.data.message } });
      }
      else {
        console.log("Error.........!");
        enqueueSnackbar("Server Error...!", { variant: 'error' });
      }
    }
    catch (error) {
      console.log("Delete error : ", error);
    }
  }

  const handleLeadMenuItemClick = async (id, newStatus) => {
    setIsOpen(false)
    setOpenDeletePop(false);
    await changeLeadStatus(id, newStatus);
  }

  const handleNavigate = (contactId) => {
    navigate(`${PATH_DASHBOARD.client.addClient}/${'client'}`, { state: { crmName: name, crmEmail: email,crmPhone: phone,fromCrm:true } });
  };

  const handleLeadNavigate = (lead) => {
    navigate(`${PATH_DASHBOARD.client.addClient}/${'client'}`, { state: { crmName: leadName, crmEmail: leadEmail,crmPhone: leadPhone,fromCrm:true } });
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {/* sx={{ color: 'text.secondary' }} */}
        <MenuItem onClick={()=>{onEdit(id || leadId)}}> 
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem
        onClick={() => {
          if(explodedPermissions?.some(permission => ['Inviting clients'].includes(permission))){
            leadId ? handleLeadNavigate(leadId) : handleNavigate(id)
          }else{
            enqueueSnackbar("Access Denied: You do not have sufficient permissions to add client", { variant: 'error' })
          }
          }}>
          <ListItemIcon>
            <LibraryBooksOutlined sx={{width:25,height:25}}/>
          </ListItemIcon>
          <ListItemText primary="Convert to Client" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        {typeof status !== 'undefined'  ? (
          status === 1 ? (
            <MenuItem
              onClick={() => {
                if(explodedPermissions?.some(permission => ['Activate/Deactivate Contact'].includes(permission))){
                  handleMenuItemClick(id, 0)
                }else{
                  enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
                }
              }}
            >
              <ListItemIcon>
                <BlockOutlined sx={{ width: 25, height: 25 }} />
              </ListItemIcon>
              <ListItemText primary="Deactivate" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() => {
                if(explodedPermissions?.some(permission => ['Activate/Deactivate Contact'].includes(permission))){
                  handleMenuItemClick(id, 1)
                }else{
                  enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
                }
              }}
            >
              <ListItemIcon>
                <TaskAltOutlined sx={{ width: 25, height: 25 }} />
              </ListItemIcon>
              <ListItemText primary="Activate" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
          )
        ) : typeof leadStatus !== 'undefined' ? (
          leadStatus === 1 ? (
            <MenuItem
            onClick={() => {
              if(explodedPermissions?.some(permission => ['Activate/Deactivate Lead'].includes(permission))){
                handleLeadMenuItemClick(leadId, 0)
              }else{
                enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
              }
              }}>
              <ListItemIcon>
                <BlockOutlined sx={{ width: 25, height: 25 }} />
              </ListItemIcon>
              <ListItemText primary="Deactivate" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() => {
                if(explodedPermissions?.some(permission => ['Activate/Deactivate Lead'].includes(permission))){
                  handleLeadMenuItemClick(leadId, 1)
                }else{
                  enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
                }
              }}>
              <ListItemIcon>
                <TaskAltOutlined sx={{ width: 25, height: 25 }} />
              </ListItemIcon>
              <ListItemText primary="Activate" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
          )
        ) : null}


        <MenuItem
        onClick={()=>{
          if(leadId){
            if(explodedPermissions?.some(permission => ['Delete Lead'].includes(permission))){
              setOpenDeletePop(true),setIsOpen(false)
            }else{
              enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
            }
          }else{
            if(explodedPermissions?.some(permission => ['Delete Contact'].includes(permission))){
              setOpenDeletePop(true),setIsOpen(false)
            }else{
              enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
            }
          }}}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>

    {/* Confirmation for deleting contact  */}
    <ConfirmationPopup
      open={openDeletePop}
      handleClose={()=>{setOpenDeletePop(false)}}
      message={`You want to delete ${ leadName ? leadName : name} ! `}
      handleClick={()=>{
        leadId ? handleLeadMenuItemClick(leadId,2) : handleMenuItemClick(id, 2)
      }}
    />
    </>
  );
}
