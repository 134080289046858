
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { sentenceCase } from 'change-case';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import {
  Container, Grid, IconButton,
  Stack, Box, Button, TableRow,
  TableBody, Checkbox, Card,
  TableCell, Typography,
  TableContainer, Table,
  TablePagination,
  useTheme, TextField,
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { KeyboardBackspaceOutlined } from '@material-ui/icons';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import { getPost, getRecentPosts } from '../../redux/slices/blog';
import { LoadingButton } from '@material-ui/lab';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import { Link as RouterLink } from 'react-router-dom'
// components
import Page from '../../components/Page';
import Markdown from '../../components/Markdown';
import Scrollbar from '../../components/Scrollbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

import Modal from '../../components/_dashboard/blog/ProjectModel';
import Label from 'src/components/Label';
import { UserListHead, UserMoreMenu } from 'src/components/_dashboard/user/list';
import axios from 'axios';
import { REST_API_END_POINT } from 'src/constants/Defaultvalues';
import { filter } from 'lodash';
import ProductToolbar from 'src/components/_dashboard/user/list/ProductToolbar';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import ConfirmationPopup from 'src/components/_dashboard/official-request/ConfirmationPopup';
import LoadingScreen from 'src/components/LoadingScreen';
import SearchNotFound from 'src/components/SearchNotFound';

export default function leadstatus() {
  const theme = useTheme()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = useState(false)
  const [editId, setEditId] = useState()
  const [selected, setSelected] = useState([])
  const [leadStatusData, setLeadStatusData] = useState([])
  const [filterName, setFilterName] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const userSession = JSON.parse(localStorage.getItem('user'))
  const userId = userSession.user_id;
  const explodedPermissions =userSession?.exploded_permissions
  const [receivedData, setReceivedData] = useState('')
  const [openDeletePop,setOpenDeletePop] = useState(false);
  const [deleteId,setDeleteId] = useState();
  const [deleteName,setDeleteName] = useState();
  const [loading,setLoading] = useState(true);
  const [editData,setEditData] = useState([]);

  const fetchData = async () => {
    setLoading(true)
    try{
      const response = await axios.post(`${REST_API_END_POINT}leads/lead-status`)
      if(response.data){
        // console.log("Lead status data",response.data);
        setLeadStatusData(response.data)
      }
      else{
        setLeadStatusData([])
      }
    }
    catch(error){
      console.log("fetchData error",error);
    }
    finally{
      setLoading(false)
    }
  }

  const fetchEditLeadStatusData = async () => {
    setLoading(true)
    try{
      const response = await axios.post(`${REST_API_END_POINT}leads/get-lead-status-by-id`,{id:editId});
      if(response.data){
        // console.log("edit lead data",response.data);
        setEditData(response.data)
      }
    }
    catch(error){
      setEditData([])
      console.log("fetchEditLeadStatusData error",error);
    }
    finally{
      setTimeout(() => {
        setLoading(false)
      }, 500);
    }
  }

  useEffect(()=>{
    fetchData();
    if(editId){
      fetchEditLeadStatusData();
    }
  },[receivedData, editId])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const TABLE_HEAD = [
    { id: 0, label: 'Name', alignRight: false },
    { id: 1, label: 'Added By', alignRight: false },
    { id: 2, label: 'Added On', alignRight: false },
    { id: 4, label: 'Status', alignRight: false },
    { id: 5, }
  ]

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),

  })
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name : (editData?.name) ? editData.name : ''
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        console.log({values});
        const endpoint = editId ? `${REST_API_END_POINT}leads/edit-lead-status` : `${REST_API_END_POINT}leads/add-lead-status`;
        let dataToSend = editId ? { values, editId, userId } : { values, userId } ;
        const response = await axios.post(endpoint,dataToSend)
        if(response.data.status === 1){
          enqueueSnackbar(response.data.message, { variant: 'success' });
        }
        else if(response.data.status === 0){
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
        resetForm()
        setSubmitting(false)
        setOpen(false)
        setEditId()
        setEditData([])
        setReceivedData('');
        setReceivedData(response.data.message);
        navigate(PATH_DASHBOARD.crm.leadstatus)
      } catch (error) {
        console.error(error)
        setSubmitting(false)
        setErrors(error)
      }
    },
  })

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
  } = formik

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredUsers.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  function applySortFilter(array, query) {
    if (query) {
      return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return array;
  }

  const changeStatus = async (leadStatusId, leadStatusStatus) => {
    try {
      let deleteId = leadStatusId;
      let status = leadStatusStatus;
      let response = await axios.post(`${REST_API_END_POINT}leads/delete-lead-status`,{deleteId,status,userId});
    //   console.log("response-----------------", response.data);
      if (response.data.status === 1) {
        enqueueSnackbar(response.data.message, { variant: 'success' });
      }
      else if (response.data.status === 0) {
        enqueueSnackbar(response.data.message, { variant: 'error' });
      }
      setReceivedData('');
      setReceivedData(response.data.message);
      navigate(PATH_DASHBOARD.crm.leadstatus);
    }
    catch (error) {
      enqueueSnackbar("Server Error...!", { variant: 'error' });
      console.log("Delete error : ", error);
    }
  }

  const handleMenuClick = async (id, status) => {
    let newStatus;
    (status === 1) ? newStatus = 0 : newStatus = 1
    // console.log("id, newStatus",id, newStatus);
    await changeStatus(id, newStatus);
  }

  const handleDeleteClick = async (id) => {
    setOpenDeletePop(false);
    setDeleteId();
    setDeleteName();
    let newStatus = 2;
    await changeStatus(id, newStatus);
  }

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleCancel = (formik) => {
    setEditData([]);
    setEditId();
    setOpen(false)
    formik.resetForm();
  };


  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - leadStatusData.length) : 0;
  const filteredUsers = applySortFilter(leadStatusData, filterName);
  // console.log("filteredUsers",filteredUsers);
  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="All Lead Statuses | Sign-Wise Solution">
      <Container>
      <Button
          variant="outlined"
          type="submit"
          component={RouterLink}
          to={PATH_DASHBOARD.crm.lead}
          sx={{ alignItems: 'flex-end', mb: 3.5 }}
          startIcon={ // Use the startIcon prop to add an icon to the left
            <Stack>
              <KeyboardBackspaceOutlined/>
            </Stack>

          }
        >
          Back to All Leads
        </Button>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
        
          <HeaderBreadcrumbs
            heading="All Lead Statuses"
          />
          <Box>
            <Button
              disabled={explodedPermissions?.some(permission => ['Add Lead Status'].includes(permission)) ? false : true}
              onClick={() => {
                setOpen(true)
                setEditId()
                setEditData([])
              }}
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
            >
              Add Lead Statuses
            </Button>
          </Box>
        </Stack>
        {!open ? (
          loading ? 
            <LoadingScreen
                sx={{
                    top: 0,
                    left: 0,
                    width: 1,
                    zIndex: 9999,
                    position: 'fixed'
                }}
              />
              :
              (<Card>
                      <ProductToolbar
                      numSelected={selected.length} 
                      rowCount={filteredUsers.length}
                      filterName={filterName} 
                      onFilterName={handleFilterByName}
                      selected={selected} 
                      onDelete={() => setSelected([])}
                      userId={userId}
                      item="lead statuses"
                      onUpdateList={(data)=>{setReceivedData('');setReceivedData(data)}}
                      />
                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                      <Table>
                        <UserListHead
                          //   order={order}
                          //   orderBy={orderBy}
                          headLabel={TABLE_HEAD}
                          // from={'onboard'}
                            rowCount={filteredUsers?.length}
                            numSelected={selected.length}
                            // onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                          showAllCheckBox
                        />
                        <TableBody>
                          {filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            const { id, name, status, added_by, timestamp} = row;
                            const isItemSelected = selected.indexOf(id) !== -1;
                            return (
                              <TableRow
                                hover
                                key={id}
                                tabIndex={-1}
                                // role="checkbox"
                                // selected={isItemSelected}
                                // aria-checked={isItemSelected}
                              >
                                <TableCell>
                                  <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id)} />
                                </TableCell>

                                <TableCell align="center">{name}</TableCell>
                                <TableCell align="center">{added_by}</TableCell>
                                <TableCell align="center">{moment.unix(timestamp).format('MM/DD/YYYY')}</TableCell>
                                <TableCell align="center">
                                  <Label
                                    variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                                    color={(status === 1 && 'info') || 'error'}
                                  >
                                    {sentenceCase(status === 1 ? "Active" : "Inactive")}
                                  </Label>

                                </TableCell>

                                <TableCell align="center">
                                  <UserMoreMenu
                                    onDelete ={()=>{
                                      if(explodedPermissions?.some(permission => ['Delete Lead Status'].includes(permission))){
                                        setOpenDeletePop(true);setDeleteId(id);setDeleteName(name);
                                      }else{
                                        enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
                                      }
                                    }}
                                    onEdit={()=>{
                                      if(explodedPermissions?.some(permission => ['Edit Lead Status'].includes(permission))){
                                        setOpen(true); setEditId(id);
                                      }else{
                                        enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
                                      }
                                    }}
                                    onStatusChange ={()=>{
                                      if(explodedPermissions?.some(permission => ['Activate/Deactivate Lead Status'].includes(permission))){
                                        handleMenuClick(id,status)
                                      }else{
                                        enqueueSnackbar("Access Denied: You do not have sufficient permissions", { variant: 'error' })
                                      }
                                    }}
                                    status={status}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                        {isUserNotFound && (
                          <TableBody>
                            <TableRow>
                              <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                <SearchNotFound searchQuery={filterName} msg="lead status"/>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  </Scrollbar>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50,100]}
                    component="div"
                    count={filteredUsers.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                  {/* Confirmation for deleting multiple */}
                  <ConfirmationPopup
                    open={openDeletePop}
                    handleClose={()=>{setOpenDeletePop(false)}}
                    message={`You want to delete ${deleteName} ! `}
                    // message={`You want to delete : ${selectedName.join(', ')} !`}
                    handleClick={() => handleDeleteClick(deleteId)}
                  />
                </Card>)
          ) : (
            <Modal
              modalTitle={editId ? 'Edit Lead Status' : 'Add Lead Status'}
              open={open == true}
              handleClose={() => {
                setOpen(false)
                setEditId()
              }}>

              <FormikProvider value={formik}>
                <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={8}>
                      <Stack spacing={3}>
                        <Stack
                          direction={{ xs: 'column', sm: 'row' }}
                          spacing={{ xs: 3, sm: 2 }}
                        >
                          <TextField
                            fullWidth
                            label="Name"
                            {...getFieldProps('name')}
                            error={Boolean(touched.name && errors.name)}
                            helperText={touched.name && errors.name}
                          />
                        </Stack>
                        <Box
                          sx={{
                            mt: 3,
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: 1,
                          }}
                        >
                          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                            Save
                          </LoadingButton>
                          <Button variant="outlined" onClick={() => {handleCancel(formik)}}>
                            Cancel
                          </Button>
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </Modal>
          )}
      </Container>
    </Page>
  );
}