import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'
import { useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import QRCode from 'react-qr-code'
import ReactToPrint from 'react-to-print'
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill'
import {
  PrintOutlined,
} from '@material-ui/icons'
// material
import { useTheme } from '@material-ui/core/styles'
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'notistack'
import { PATH_DASHBOARD } from 'src/routes/paths'
import { BASE_URL, COMPANY_NAME } from 'src/constants/Defaultvalues'
import { empty } from 'src/Helpers/Common'

// routes

// ----------------------------------------------------------------------

// const labelStyle = {
//   width: '90mm',
//   height: 'auto',
//   padding: '3mm',
//   fontFamily: 'Arial, sans-serif',
//   fontSize: '10px',
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'space-between',
//   // border: '1px solid #000',
//   boxSizing: 'border-box',
//   gap: '5mm',
// };

const labelStyle = { width: '62mm',
   height: '30mm', // Set the height according to your label size 
   padding: '1mm', // Adjust as necessary 
   fontFamily: 'Arial, sans-serif', fontSize: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', boxSizing: 'border-box', gap: '2mm', 
   // Adjust the gap to ensure spacing fits the label
    overflow: 'hidden', };
const rowStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  gap:2
};

const sectionStyle = {
  display: 'flex',
  flexDirection: 'column',
};

const qrCodeSection = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '1mm',
};

const typographyStyle = {
  fontWeight: 'bold',
  textAlign: 'center',
  color:'black',

};

export default function FinishedProductmenu({ id,url,useIcon, name }) {
  const ref = useRef(null)
  const componentRef = useRef(null)
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  let user = JSON.parse(localStorage.getItem('user'))

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <IconButton ref={ref} color='#ffffff'>
          {/* {useIcon ? <PrintOutlined /> : <Icon icon={moreVerticalFill} width={20} height={20} />} */}
          <PrintOutlined />
          {/* {useIcon && <Typography variant="body2" sx={{ marginLeft: 1 }}>Print</Typography>} */}
          </IconButton>
        )}
        content={() => componentRef.current}
      />
    <div style={{ display: 'none' }}>
      <div
        ref={componentRef}
        style={labelStyle}
        // style={{
        //   display: 'flex',
        //   flexDirection: 'row',
        //   border:"5px solid",
        //   borderColor:"black",
        //   width:"50%",
        //   // alignItems: 'center',
        //   justifyContent: 'space-between',
        //   // marginTop: 120,
        // }}
      >
        <div style={rowStyle}>
       {/* <h1 style={{color:'#000000', marginTop:30}}>{COMPANY_NAME}</h1>
       {!empty(name) && <h2 style={{color:'#000000', marginTop:30}}>{name}</h2>} */}
        {!empty(name) && 
        <>
        <div style={qrCodeSection}>
        <QRCode
          value={name}
          size={50}
          // style={{display:'flex',justifyContent:'center',alignItems:'center', marginTop:20}}
          bgColor={'#ffffff'}
          fgColor={'#000000'}       
          includeMargin={false}
        />
        {/* <h3 style={{color:'#000000', marginTop:10,fontSize:"15px"}}>Scan Material</h3> */}
        <Typography variant="caption" sx={{color:'black'}}>Scan Material</Typography>
        </div>
        </>}
        <div style={sectionStyle}>
        {/* <h1 style={{color:'#000000', marginTop:20,fontSize:"20px"}}>{COMPANY_NAME}</h1> */}
        {/* <Typography variant="h3" style={typographyStyle}>{COMPANY_NAME}</Typography> */}
        {!empty(name) && 
        // <h2 style={{color:'#000000', marginTop:10,fontSize:"20px"}}>{name}</h2>
        <Typography variant="h5" style={typographyStyle}>{name}</Typography>
        }
        </div>
        <div style={qrCodeSection}>
        <QRCode
          value={`${url}${id}`}
          size={50}
          // style={{display:'flex',justifyContent:'center',alignItems:'center', marginTop:20}}
          bgColor={'#ffffff'}
          fgColor={'#000000'}       
          includeMargin={false}
        />
        {/* <h3 style={{color:'#000000', marginTop:10,fontSize:"15px"}}>Scan for Details</h3> */}
        <Typography variant="caption" sx={{color:'black'}}>Scan for Details</Typography>
        </div>
        </div>
      </div>
    </div>
  </>

  )
}
