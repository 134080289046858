import faker from 'faker';
import { sentenceCase } from 'change-case';
// material
import { useTheme } from '@material-ui/core/styles';
import {
  Box,
  Card,
  Table,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  Typography,
  TableContainer,
  TablePagination
} from '@material-ui/core';
// utils

//
import Label from 'src/components/Label';
import Scrollbar from 'src/components/Scrollbar';
import { useEffect, useState } from 'react';
import moment from 'moment';
import SearchNotFound from 'src/components/SearchNotFound';

export default function Calls({twilioCallsData}){
    const theme = useTheme();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0)
    const [twilioCallsArray,setTwilioCallsArray] = useState([]);

    useEffect(()=>{
      if(twilioCallsData){
        setTwilioCallsArray(twilioCallsData)
      }
      else{
        setTwilioCallsArray([])
      }
    },[])

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const isUserNotFound = twilioCallsArray.length === 0;

    return(
        <Card sx={{ pb: 3 }}>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 720 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell sx={{width: 160 }} align='center'>Date</TableCell>
                <TableCell align='center'>Direction</TableCell>
                <TableCell align='center'>From</TableCell>
                <TableCell align='center'>To</TableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
              {twilioCallsArray
              .slice(0)
              .sort((a, b) => new Date(b.date_created) - new Date(a.date_created))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'} color={(((row.call_status === 'completed') || (row.call_status === 'in-progress')) && 'info') || 'error'} >
                      {sentenceCase(row.call_status)}
                    </Label> 
                  </TableCell>
                  <TableCell align='center'>{moment.unix(row.date_created).format('DD-MM-YYYY')}</TableCell>
                  <TableCell align='center'>{ (row.direction === "outbound-dial")  ? "Outgoing" : "Incoming" }</TableCell>
                  <TableCell align='center'>{row.from}</TableCell>
                  <TableCell align='center'>{row.to}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound msg="Calls"/>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
            rowsPerPageOptions={[10, 25, 50,100]}
            component="div"
            count={twilioCallsArray.length}
             rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> 
    </Card>
    )
}