import Logo from '../../src/Gif/1721042180148Logo-With-White-BG.png'
export const GOOGLE_API_KEY = "AIzaSyB0VFLrPEaYGqP4FVdGDEeUUN6Ks9AZPao"; 
// export const GOOGLE_API_KEY = "AIzaSyBe6vrykAsiKjwIHWOUcfH1Li4VT-2LHTg";

// export const REST_API_END_POINT = "http://localhost:4000/webservice/";
// export const REST_API = "http://localhost:4000/";
// export const BASE_URL = "http://localhost:3000/";

// arizona.signwisesolutions.com
// export const BASE_URL = "https://arizona.signwisesolutions.com/"
// export const REST_API_END_POINT = "https://arizona.signwisesolutions.com/backend/webservice/";
// export const REST_API = "https://arizona.signwisesolutions.com/backend/";

// oklahoma.signwisesolutions.com/
export const BASE_URL = "https://oklahoma.signwisesolutions.com/"
export const REST_API_END_POINT = "https://oklahoma.signwisesolutions.com/backend/webservice/";
export const REST_API = "https://oklahoma.signwisesolutions.com/backend/";

// riverside.signwisesolutions.com/
// export const BASE_URL = "https://riverside.signwisesolutions.com/"
// export const REST_API_END_POINT = "https://riverside.signwisesolutions.com/backend/webservice/";
// export const REST_API = "https://riverside.signwisesolutions.com/backend/";

//la.signwisesolutions.com/
// export const BASE_URL = "https://la.signwisesolutions.com/"
// export const REST_API_END_POINT = "https://la.signwisesolutions.com/backend/webservice/";
// export const REST_API = "https://la.signwisesolutions.com/backend/";

// //manteca.signwisesolutions.com/
// export const BASE_URL = "https://manteca.signwisesolutions.com/"
// export const REST_API_END_POINT = "https://manteca.signwisesolutions.com/backend/webservice/";
// export const REST_API = "https://manteca.signwisesolutions.com/backend/";

//denver.signwisesolutions.com/
// export const BASE_URL = "https://denver.signwisesolutions.com/"
// export const REST_API_END_POINT = "https://denver.signwisesolutions.com/backend/webservice/";
// export const REST_API = "https://denver.signwisesolutions.com/backend/";

//cosprings.signwisesolutions.com/
// export const BASE_URL = "https://cosprings.signwisesolutions.com/"
// export const REST_API_END_POINT = "https://cosprings.signwisesolutions.com/backend/webservice/";
// export const REST_API = "https://cosprings.signwisesolutions.com/backend/";

//lasvegas.signwisesolutions.com/
// export const BASE_URL = "https://lasvegas.signwisesolutions.com/"
// export const REST_API_END_POINT = "https://lasvegas.signwisesolutions.com/backend/webservice/";
// export const REST_API = "https://lasvegas.signwisesolutions.com/backend/";


const user =  JSON.parse(localStorage.getItem('user'))

export const COMPANY_LOGO = user?.company_logo || Logo
export const COMPANY_NAME = user?.company_name || 'Signwise Solutions Oklahoma'



// arizona
// export const VERSION = '1.32'; 

// Other Installations
export const VERSION = '0.07';

export const PUBNUB_PUBLISH_KEY= 'pub-c-014b4ee8-2c24-46d0-ae01-4a933c3fee2e'
export const PUBNUB_SUBSCRIBE_KEY= 'sub-c-53f81de7-6961-4d0e-87bb-f3defdef49a0'
export const PUBNUB_SECRET_KEY="sec-c-NTY0NDYwZDEtN2M3MC00Y2YwLWE4ZmItMzdmMjc5MTY1YTNl"

export const UNITS = [{id:1,name:'Square feet'}, {id:2,name:'Lineal feet'}, {id:3,name:'Square Inches'}, {id:4,name:'Unit'}]

// wiseai.signwisesolutions.com
// export const BASE_URL = "https://wiseai.signwisesolutions.com/"
// export const REST_API_END_POINT = "https://wiseai.signwisesolutions.com/backend/webservice/";
// export const REST_API = "https://wiseai.signwisesolutions.com/backend/";

// dev.signwisesolutions.com
// export const BASE_URL = "https://dev.signwisesolutions.com/"
// export const REST_API_END_POINT = "https://dev-api.signwisesolutions.com/webservice/";
// export const REST_API = "https://dev-api.signwisesolutions.com/";

// my.signwisesolutions.com
// export const REST_API_END_POINT = "https://api.signwisesolutions.skyniche.website/webservice/";
// export const REST_API = "https://api.signwisesolutions.skyniche.website/";
// export const BASE_URL = "https://my.signwisesolutions.com/";