import * as Yup from 'yup'
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// material
import { useTheme } from '@material-ui/core/styles';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
  Grid,
  TextField,
  Box,
  Autocomplete,
} from '@material-ui/core';
// redux
import { useDispatch } from '../../redux/store';
import { getUserList, deleteUser } from '../../redux/slices/user';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { UserListHead, UserMoreMenu } from '../../components/_dashboard/user/list';
import plusFill from '@iconify/icons-eva/plus-fill';
import axios from 'axios';
import { COMPANY_NAME, REST_API_END_POINT } from '../../constants/Defaultvalues';
import MaterialInventoryToolbar from 'src/components/_dashboard/user/list/MaterialInventoryToolbar';
import Label from 'src/components/Label';
import { sentenceCase } from 'change-case';
import moment from 'moment';
import Modal from 'src/components/_dashboard/blog/ProjectModel';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@material-ui/lab';
import ConfirmationPopup from 'src/components/_dashboard/official-request/ConfirmationPopup';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'name', label: 'Category Name', alignRight: false },
  { id: 'value', label: 'Tax', alignRight: false },
  { id: 'updated_by', label: 'Updated By', alignRight: false },
  { id: 'timestamp', label: 'Updated On', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '', label: '', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

const TaxType = [
  { name : '%', id : 1},
  { name : '$', id : 2},
]

export default function TaxcategoryList(props) {
  const theme = useTheme();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar()
  const userData = JSON.parse(localStorage.getItem('user'))
  const explodedPermissions = userData?.exploded_permissions
  const userId = userData.user_id
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const location = useLocation();
  const receivedData = location.state?.data;
  const [taxCategories, setTaxCategories] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const [editData, setEditData] = useState(null);
  const [editId, setEditId] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [openDeletePop,setOpenDeletePop] =useState(false);
  const [deletingId,setDeletingId] = useState(null);
  const [deletingName,setDeletingName] = useState('');

  useEffect(() => {
    dispatch(getUserList());
  }, [dispatch]);

  const fetchTaxCategory = async () => {
    setLoading(true)
    try{
        const response = await axios.post(`${REST_API_END_POINT}product/get-all-tax-category`)
        if(response.data.status === 1){
            setTaxCategories(response.data.taxResult);
        }else{
          setTaxCategories([])
        }
    }catch(error){
        console.log("Error - ",error);
        setTaxCategories([])
    }
    finally{
        setLoading(false)
    }
  }

  useEffect(()=>{
      fetchTaxCategory()
  },[refreshData])

  const handleDeleteClick = async (id, newStatus) => {
    setDeletingName('');
    setDeletingId(null);
    setOpenDeletePop(false);
    await changeStatus(id, newStatus);
  }

  const handleMenuClick = async (id, status) => {
    let newStatus;
    (status === 1) ? newStatus = 0 : newStatus = 1
    await changeStatus(id, newStatus);
  }
  
  const handleEdit = async (id) => {
    setEditId(id)
    try{
      const response = await axios.post(`${REST_API_END_POINT}product/get-tax-category-by-id`,{id})
      if(response.data.status === 1){
          setEditData(response.data.taxResult);
          setOpenModel(true);
      }else{
        setEditId(null);
        setEditData(null);
      }
    }catch(error){
      console.log("Error - ",error);
      setEditId(null);
      setEditData(null);
    }
  }
  const changeStatus = async (comId, comStatus) => {
    try {
      let deleteId = comId;
      let status = comStatus;
      let response = await axios.post(`${REST_API_END_POINT}product/delete-tax-category`,{deleteId,status,userId});
      if (response.data.status === 1) {
        enqueueSnackbar(response.data.message, { variant: 'success' });
        setRefreshData(!refreshData)
      }
      else if (response.data.status === 0) {
        enqueueSnackbar(response.data.message, { variant: 'error' });
        setRefreshData(!refreshData)
      }
      else {
        console.log("Error.........!");
        enqueueSnackbar("Server Error...!", { variant: 'error' });
        setRefreshData(!refreshData)
      }
    }
    catch (error) {
      console.log("Delete error : ", error);
    }
  }

  // ...
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    setPage(0)
  };

  const handleDeleteUser = (userId) => {
    dispatch(deleteUser(userId));
  };

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    type: Yup.object().nullable().required('Tax type is required'),
    taxValue: Yup.number().typeError('Please enter a valid tax rate').required('Tax rate is required'),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: editData?.name || '',
      taxValue: editData?.value || 0,
      type: editData?.type ? (TaxType?.find(item=> item.id == editData?.type) || TaxType[0]) : TaxType[0],
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        const response = await axios.post(
          REST_API_END_POINT + (editId ? 'product/update-tax-category' : 'product/add-tax-category'),
          {values, userId, editId}
        )
        let data = response.data;
        resetForm();
        setSubmitting(false);
        setRefreshData(!refreshData);
        setOpenModel(false);
        enqueueSnackbar(`${data.message}`, { variant: data.status === 1 ? 'success' : 'error' });
      } catch (error) {
        console.error(error);
        enqueueSnackbar(`Server Error`, { variant: 'error' });
      }
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - taxCategories.length) : 0;
  const filteredUsers = applySortFilter(taxCategories, getComparator(order, orderBy), filterName);
  const slicedData = filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title={`Tax Categories | ${COMPANY_NAME}`}>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <HeaderBreadcrumbs
            heading="Tax Categories"
          />
          <Box>
            <Button
              // disabled={explodedPermissions?.some(permission => ['Add Catalog'].includes(permission)) ? false : true}
              sx={{mr:2}}
              onClick={()=>{ setOpenModel(true); }}
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
            > Add New </Button>
          </Box>
        </Stack>
        {/* <Stack direction="row" alignItems="center" justifyContent="center" sx={{mb:3}}>
            <Stack sx={{py:0.5,px:2,borderRadius:2,backgroundColor:theme.palette.error.main}}>
                <Typography sx={{color:theme.palette.text.primary}}>Please contact super admin to update tax categories</Typography>
            </Stack>
        </Stack> */}
        <Grid container spacing={3} justifyContent="center" alignItems="center">
            <Grid item xs={12} md={10}>
                <Card>
                <MaterialInventoryToolbar
                filterName={filterName} 
                onFilterName={handleFilterByName}
                item="Tax Categories"
                />

                <Scrollbar>
                    <TableContainer sx={{ minWidth: 500 }}>
                    <Table>
                    <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={slicedData?.length}
                        onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                        {loading ? (
                            <CircularProgress color="primary" variant="indeterminate" />
                            ) : (
                            <>
                        {slicedData?.map((row) => {
                            let { id,name, value, type, updated_by, timestamp, status } = row;
                            let statusText = (status === 1) ? "Active" : "Inactive";
                            return (
                            <TableRow
                                hover
                                key={id}
                                tabIndex={-1}
                            >
                                <TableCell padding="checkbox">
                                {/* <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id,name)} /> */}
                                </TableCell>
                                <TableCell align="center">{id}</TableCell>
                                <TableCell component="th" scope="row" padding="none">
                                  <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
                                    <Typography variant="subtitle2" noWrap onClick={()=>{navigate(`${PATH_DASHBOARD.products.editMaterial}?id=${id}/edit`)}} style={{ cursor: 'pointer' }}>
                                    {name}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell align="center">{(type == 1) ? (value+'%') : ('$'+value)}</TableCell>
                                <TableCell align="center">{updated_by}</TableCell>
                                <TableCell align="center">{moment.unix(timestamp).format('MM/DD/YYYY')}</TableCell>
                                <TableCell align="center">
                                  <Label
                                    variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                                    color={(status === 1 && 'info') || 'error'}
                                  >
                                    {sentenceCase(statusText)}
                                  </Label>
                              </TableCell>
                                <TableCell align="center">
                                  <UserMoreMenu
                                    onDelete={()=>{setOpenDeletePop(true);setDeletingId(id);setDeletingName(name);}}
                                    onEdit={()=>{ handleEdit(id) }} 
                                    onStatusChange={()=>handleMenuClick(id,status)}
                                    status={status}
                                />
                              </TableCell>
                            </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                            </TableRow>
                        )}
                        </>
                            )}
                        </TableBody>
                        {isUserNotFound && (
                        <TableBody>
                            <TableRow>
                            <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                <SearchNotFound searchQuery={filterName} msg="Tax Categories"/>
                            </TableCell>
                            </TableRow>
                        </TableBody>
                        )}
                    </Table>
                    </TableContainer>
                </Scrollbar>

                <TablePagination
                    rowsPerPageOptions={[10, 25, 50,100]}
                    component="div"
                    count={taxCategories.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                </Card>
            </Grid>
        </Grid>
      </Container>
      <Modal
        modalTitle={editId ? 'Edit Tax Category' : 'Add Tax Category'}
        open={openModel}
        handleClose={() => {setOpenModel(false); setEditId(null); setEditData(null);}}
      >
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} md={8}>
                <Stack spacing={3}>
                  <Stack direction={{ xs: 'column', sm: 'column' }} spacing={{ xs: 3, sm: 2 }}>
                    <TextField
                        fullWidth
                        label={'Name'}
                        {...getFieldProps('name')}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                    />
                    {/* <TextField
                      fullWidth
                      label="Tax Rate"
                      type="number"
                      {...getFieldProps('taxValue')}
                      onKeyPress={(event) => {
                        if (event.key === '-' || event.key === 'e') {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => {
                        let value = parseFloat(event.target.value);
                        // if (value < 0) {
                        //   value = 0;
                        // } else if (value > 100) {
                        //   value = 100;
                        // }
                        setFieldValue('taxValue', value);
                      }}
                      // inputProps={{ max: 100, min: 0 }}
                      error={Boolean(touched.taxValue && errors.taxValue)}
                      helperText={(touched.taxValue && errors.taxValue) 
                        // || 'Enter a value between 0 and 100'
                      }
                    /> */}
                     <TextField
                    fullWidth
                    label="Tax Rate"
                    type="tel"
                    {...getFieldProps('taxValue')}
                    onKeyDown={(event) => {
                      // List of allowed keys: Numbers (0-9), Backspace, Delete, ArrowLeft, ArrowRight
                      const allowedKeys = [
                        'Backspace',       // Backspace for deletion
                        'Delete',          // Delete key for removing
                        'ArrowLeft',       // Left arrow for cursor movement
                        'ArrowRight',      // Right arrow for cursor movement
                        'Tab',             // Tab for navigation (optional)
                        '.',               // Decimal point (only one allowed)
                        '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'  // Numeric keys 0-9
                      ];

                      // If the pressed key is not in the allowed list, prevent its default behavior
                      if (!allowedKeys.includes(event.key)) {
                        event.preventDefault();  // Block invalid keys (special characters and letters)
                      }

                      // Allow only one decimal point
                      if (event.key === '.' && event.target.value.includes('.')) {
                        event.preventDefault(); // Prevent entering more than one decimal point
                      }
                    }}
                    onChange={(event) => {
                      let value = event.target.value;

                      // If the input contains more than one decimal point, remove the extra one
                      if (value.split('.').length > 2) {
                        value = value.slice(0, -1); // Remove the last character if multiple decimal points exist
                      }

                      // Update the value in Formik
                      setFieldValue('taxValue', value);
                    }}
                    error={Boolean(touched.taxValue && errors.taxValue)}
                    helperText={touched.taxValue && errors.taxValue}
                  />
                    <Autocomplete
                      fullWidth
                      options={TaxType || []}
                      value={values.type}
                      onChange={(event, newValue) => {
                        setFieldValue('type', newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} required
                          error={Boolean(touched.type && errors.type)}
                          helperText={touched.type && errors.type}
                          label="Select a tax type" />
                      )}
                      getOptionLabel={(option) => option.name ? option.name : ''}
                    />
                  </Stack>

                  <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                      {editId ? 'Save Changes' : 'Add Tax Category'}
                    </LoadingButton>
                    <Button variant="outlined" onClick={()=>{setOpenModel(false); formik.resetForm(); setEditId(null); setEditData(null)}} sx={{ marginLeft: '10px' }}>
                      Cancel
                    </Button>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Modal>
      <ConfirmationPopup
        open={openDeletePop}
        handleClose={()=>{setOpenDeletePop(false)}}
        message={`You want to delete ${deletingName} ! `}
        handleClick={()=>{handleDeleteClick(deletingId, 2)}}
      />
    </Page>
  );
}
